import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Card from './Card';
import CardComponent from './CardComponent';
import ConditionsAndTreatments from './ConditionsAndTreatments';
import DeliveredProjects from './DeliveredProjects';
import FeaturesSection from './FeaturesSection';
import InvestmentSection from './InvestmentSection';
import LandingPage from './LandingPage';
import ResponsiveSection from './ResponsiveSection';
import SliderComponent from './SliderComponent';
import Video from './Video';
import Footer from './Footer';
import PrivacyPolicy from './PrivacyPolicy';

const DefaultLayout = () => (
  <>
    <LandingPage />
    <InvestmentSection />
    <FeaturesSection />
    <SliderComponent />
    <CardComponent />
    <Card />
    <DeliveredProjects />
    <Video />
    <ResponsiveSection />
    <ConditionsAndTreatments />
    <Footer />
  </>
);

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/*" element={<DefaultLayout />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
