import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './CardComponent.css'; // Import the custom CSS file

const CardComponent = () => {
  return (
    <>
      <div><h1>Location & Price</h1></div>
      <div className="card-container" style={{ display: 'flex', gap: '2rem' }}>
        <div className="card1">
          <img src="/image/map1.png" className="card-img-top" alt="Card image" />
          {/* <div className="card-body">
            <a href="#" className="btn custom-btn">Location</a>
          </div> */}
        </div>
        <div className="card1">
          <img src="/image/PRICE.png" className="card-img-top" alt="Card image" style={{height:"90%"}} />
          {/* <div className="card-body">
            <a href="#" className="btn custom-btn">Price</a>
          </div> */}
        </div>
        <div className="card1">
          <img src="/image/map1.png" className="card-img-top" alt="Card image" />
          {/* <div className="card-body">
            <a href="#" className="btn custom-btn">Map</a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CardComponent;
