import React, { useState } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import './LandingPage.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LandingPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });
  const [navOpen, setNavOpen] = useState(false);
  const [errors, setErrors] = useState({});
 
  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { name, email, mobile } = formData;
    const errors = {};
    if (!name.trim()) {
      errors.name = 'Name is required';
    }
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid';
    }
    if (!mobile.trim()) {
      errors.mobile = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(mobile)) {
      errors.mobile = 'Mobile number should be exactly 10 digits';
    }
  
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
  
    try {
      const response = await fetch('https://shiv-8ib2.onrender.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        toast.success('Form submitted and emails sent successfully');
        setFormData({
          name: '',
          email: '',
          mobile: '',
          message: '',
        });
      } else {
        toast.error('Error submitting form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form');
    }
  };

  return (
    <div className="landing-page">
      <header className="header">
        <img src="./image/shiv12.png" alt="Logo 1" className="logo" />
        <nav className={`navigation ${navOpen ? 'open' : ''}`}>
          <a href="/">Home</a>
          <a href="#about">About</a>
          <a href="#service">Project Highlights</a>
          <a href="#choose">Why Choose Us</a>
          <a href="#images">Actual Images</a>
        </nav>
        <a href="tel:+919582399652" className="contact-button">
          <FaPhoneAlt className="phone-icon" /> 9582399652
        </a>
        <button className="toggle-button" onClick={toggleNav}>
          ☰
        </button>
      </header>
      <div className="content">
        <div className="left">
          <img
            src="./image/homepage.png"
            alt="Background"
            className="background-img"
          />
        </div>
        <div className="right">
          <div className="form-container">
            <h2>Govt. Approved Residential Plots</h2>
            <p style={{ fontFamily: 'monospace' }}>
              Funded By SBI Bank & PNB Bank
            </p>
            <p style={{fontFamily:'monospace'}}>Price Starts From Rs 35500/Guj</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Enter your name*"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Email Id*"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="mobile"
                  placeholder="Enter 10 Digit Mobile Number*"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
                {errors.mobile && <span className="error">{errors.mobile}</span>}
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LandingPage;
