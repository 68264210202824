import React, { useState } from 'react';
import './ConditionsAndTreatments.css';
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import { IoLocation } from "react-icons/io5";
const tabs = [
  { id: 'policy', label: 'Policy Statement', content: 'At Shiv Shakti Vihar, we envision creating not just homes, but a vibrant community that enriches lives and reflects our dedication to excellence in every aspect of residential development.' },
  { id: 'adjustable', label: 'Adjustable Registration Amount', content: 'We are pleased to inform you that the registration amount paid for the plot in Shiv Shakti Vihar will be adjusted in the total purchase amount. This adjustment will be reflected in your final payment for the plot' },
  { id: 'refundable', label: 'Refundable Process', content: 'We would like to inform you that the booking amount for your recent reservation will be refunded within 20 days from the date of booking. This refund process ensures that your payment is returned promptly and efficiently.' },
  { id: 'benefits', label: 'Benefits', content: 'Government Approved, Prime Location, Bank Loan Facility, Quality Construction, Amenities Galore, Investment Potential, Peaceful Living Environment, Gated Society, 24x7 Security, Full of Amenities' },
];

function ConditionsAndTreatments() {
  const [activeTab, setActiveTab] = useState('policy');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="conditions-and-treatments">
      <h2>Conditions & Treatments</h2>
      <ul className="tabs">
        {tabs.map((tab) => (
          <li
            key={tab.id}
            className={`tab ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      {tabs.map((tab) => (
        <div key={tab.id} id={tab.id} className={`tab-content ${activeTab === tab.id ? 'active' : ''}`}>
          <p>{tab.content}</p>
        </div>
      ))}
     
    </div>
  );
}

export default ConditionsAndTreatments;
