import React from 'react';
import './InvestmentSection.css';

const InvestmentSection = () => {
  return (
    <div className="investment-section">
      <h1 id="about">About Us</h1>
      <div className="description">
        <p>
          Experience housing comfort, affordability, and connectivity with property near Jewar Airport, one of the prime property locations in India: The Shiv Shakti Vihar is one of the most desirable and highly sought-after property locations in India due to its proximity to important landmarks surrounding it.
        </p>
        <p>
          Our project is fully approved by the government, ensuring that all legal and regulatory compliances are met, giving you peace of mind and security in your investment. The approval also signifies our commitment to maintaining the highest standards of development, providing you with a home that you can trust and be proud of.
        </p>
        <p>
          Shiv Shakti Vihar is thoughtfully planned to cater to the diverse needs of modern families. We offer a range of residential plots that are ideal for building your dream home. Our project is not just about providing land; it’s about creating a community where you can live, grow, and thrive.
        </p>
        <p>
          One of the key highlights of Shiv Shakti Vihar is its proximity to Jewar International Airport. This upcoming aviation hub is set to transform the region into a major economic and residential zone, offering immense growth potential and excellent connectivity.
        </p>
      </div>
      <div className="banner">
        
      </div>
    </div>
  );
};

export default InvestmentSection;
