import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import './Card.css'; // Import the custom CSS file

const Card = () => {
  const cardData = [
    {
      imgSrc: "./image/Untitled design.png",
      title: "100 SQ. YD.",
      dimensions: "30 ft x 30 ft",
    },
    {
      imgSrc: "./image/Untitled design (1).png",
      title: "150 SQ. YD.",
      dimensions: "30 ft x 45 ft",
    }
  ];

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">Jewar Airport Plots Size</h2>
      <div className="row justify-content-center">
        <div className="col-12 mb-4">
          <div className="card h-100">
            <img src={cardData[0].imgSrc} className="card-img-top full-width-image" alt="Card image" />
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="card h-100">
            <img src={cardData[1].imgSrc} className="card-img-top full-width-image" alt="Card image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
