import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './SliderComponent.css'; // Create this CSS file for custom styles

const SliderComponent = () => {
  // const images = [
  //   './image/a1.jpeg',
  //   './image/a2.jpeg',
  //   './image/a3.jpeg',
  //   './image/a4.jpeg',
  //   './image/a5.jpeg',
  // ];
  const images1 = [
    './image/l1.jpg',
    './image/l2.jpg',
    './image/l3.jpg',
    './image/m1.jpg',
    './image/l4.jpg',
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className="slider-container">
        <h1 id='choose'>Why Choose Us ?</h1>
        <ul  >
            <li><b>Proximity to Jewar Airport -</b> Being located near the upcoming Jewar Airport ensures excellent connectivity and accessibility, making it ideal for frequent travelers and investors looking to capitalize on future development.</li>
            <li><b>Strategic Location -</b> The area's strategic location promises potential growth and appreciation in property value, driven by infrastructural developments and economic activities associated with the airport.</li>
            <li><b>Government Approvals:</b> Shiv Shakti Vihar plots are government-approved, ensuring legal compliance and peace of mind for investors regarding regulatory and development standards.</li>
            <li><b>Bank Loan Facilities:</b> With bank loans available from reputed institutions like SBI and PNB, investing in Shiv Shakti Vihar plots becomes more accessible and financially viable, offering competitive interest rates and flexible repayment options.</li>
            <li><b>Quality Infrastructure:</b> The project prioritizes quality infrastructure, including well-planned roads, utilities, and amenities, ensuring a comfortable living environment for residents.</li>
            
          </ul>
        <h2 className="slider-title" >Actual Site Pictures Of Plots In Shiv Shakti Vihar</h2>
        
          
        
        {/* <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index} className="slide">
              <img src={img} alt={`slide-${index}`} className="slide-image" />
            </div>
          ))}
        </Slider> */}
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {images1.map((img, index) => (
            <div key={index} className="slide">
              <img src={img} alt={`slide-${index}`} className="slide-image" />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default SliderComponent;
