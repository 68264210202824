import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container" id="policy">
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> [June 3, 2024]</p>

      <section>
        <h2>1. Introduction</h2>
        <p>
        Welcome to www.shishaktiviharnoida.in, operated by Shiv Shakti Vihar ("we," "us," "our," or "Shiv Shakti Vihar"). Your privacy is of utmost importance to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website. By accessing or using www.shivshaktiviharnoida.in (the "Site"), you agree to the terms of this Privacy Policy.
        </p>
      </section>

      <section>
        <h2>2. Information We Collect</h2>
        <p>
        Personal Information: We may collect personal information that you voluntarily provide to us when you register on the Site, make a purchase, subscribe to our newsletter, fill out a form, or otherwise interact with us. This information may include:<br></br>
Name, 

Email address, 

Phone number, 

Address, 

Payment information<br/>

Non Personal Information:

We may also collect non-personal information about you, including but not limited to:

Browser type

Device type

IP address

Operating system

Pages visited on our Site

Referring URL

Date and time of access

        </p>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <p>
          We use the information we collect to:
          <ul>
            <li>
            To personalize your experience on our Site</li>
            <li>
            To improve our website and services</li>
            <li>To process transactions</li>
            <li>To send periodic emails regarding your order or other products and services
            </li>
            <li>
            To follow up with you after correspondence (live chat, email, or phone inquiries)</li>
          </ul>
        </p>
      </section>

      <section>
        <h2>4. Information Sharing</h2>
        <p>
        We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:
         
        </p>
        <p>To trusted third parties who assist us in operating our website, conducting our business, or providing services to you, so long as those parties agree to keep this information confidential</p>
        <p>
        When we believe release is appropriate to comply with the law, enforce our Site policies, or protect ours or others' rights, property, or safety</p>
        <p>Third-Party Services
        Our Site may include links to third-party websites, products, or services. These third-party sites have separate and independent privacy policies. We have no control over, and are not responsible for, the content, privacy practices, or data loss of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit.</p>
      </section>

      <section>
        <h2>5. Data Protection</h2>
        <p>
        We implement a variety of security measures to maintain the safety of your personal information. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee the absolute security of your information.
        <p></p>
        </p>
      </section>

      <section>
        <h2>6. Your Rights</h2>
        <p>
          You have the right to:
          <ul>
            <li>Access, correct, or delete your personal information</li>
            <li>

Object to or restrict the processing of your personal information
</li>
            <li>Withdraw your consent at any time, where we are processing your personal information based on your consent</li>
            <li>To exercise these rights, please contact us at the information provided below.
            </li>
          </ul>
        </p>
    
      </section>

      <section>
        <h2>7. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us:
          <br />
           <strong>Email:</strong> [care@shivshaktiviharnoida.in]<br />
          <strong>Phone:</strong> [+91-9582399652]<br />
          <strong>Address:</strong> [C196, Sector 63, NOIDA, UP, INDIA -201301]
        </p>
      </section>

      <section>
        <h2>8. Changes to This Privacy Policy</h2>
        <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
          <br />
          <strong>Last updated:</strong> [5,july 2024]
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
