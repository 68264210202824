import React, { useState } from 'react';
import './ResponsiveSection.css'; // Ensure this CSS file path is correct and matches your project structure

function ResponsiveSection() {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, mobile } = formData;
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = 'Name is required';
    }
    if (!email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email address is invalid';
    }
    if (!mobile.trim()) {
      newErrors.mobile = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(mobile)) {
      newErrors.mobile = 'Mobile number should be exactly 10 digits';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await fetch('https://shiv-8ib2.onrender.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          to: email,
          subject: 'New Form Submission',
          text: `Successfully Registered \n Name: ${name}\nEmail: ${email}\nMobile: ${mobile}\nMessage: ${formData.message}`,
        }),
      });

      if (response.ok) {
        alert('Email sent successfully');
        setFormData({
          name: '',
          email: '',
          mobile: '',
          message: '',
        });
        handleCloseModal();
      } else {
        alert('Error sending email');
      }
    } catch (error) {
      console.error('Error sending email: ', error);
      alert('Error sending email');
    }
  };

  return (
    <div className="responsive-section">
      <div className="section-header">
        Residential Plots near Jewar International Airport on Yamuna Expressway.
      </div>
      <div className="section-content">
        <p>
          Buy land near Jewar Airport and the upcoming Film City on Yamuna Expressway surrounded by natural beauty. 
          Having a beautiful property in Jewar is not only a financially savvy decision but one that will lead to true contentment.
        </p>
        <p>
          Book a site visit today to authority plots in Jewar by Shiv Shakti Vihar, a very well connected place to Delhi NCR, opposite F1 Track.
        </p>
      </div>
      <button onClick={handleOpenModal}  className="site">Book Free Site Visit</button>

      {/* Modal */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>&times;</span>
           
            <h2>Book Free Site Visit</h2>
            <p>Govt. Approved Residential Plots</p>
            <p>Funded By SBI Bank And PNB Bank</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Enter your name*"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                {errors.name && <div className="error">{errors.name}</div>}
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Email Id*"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="mobile"
                  placeholder="Enter 10 Digit Mobile Number*"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                />
                {errors.mobile && <div className="error">{errors.mobile}</div>}
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
                {errors.message && <div className="error">{errors.message}</div>}
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResponsiveSection;
