import React from 'react';
import './LocalVideoPlayer.css'; // Import your CSS for styling

const LocalVideoPlayer = () => {
    return (
        <div className="video-container">
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/bJdCdb1JwYk?si=NcBAZ6dR8WW9vM5e"
                title="Project Tour"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default LocalVideoPlayer;
