import React from 'react';
import './DeliveredProjects.css'; // Ensure this CSS file exists for custom styles

const DeliveredProjects = () => {
  const images = [
    './image/l1.jpg',
    './image/l2.jpg',
    '	./image/l3.jpg',
    '	./image/l4.jpg',
  ];

  const firstRowImages = images.slice(0, 2); // First row images
  const secondRowImages = images.slice(2);  // Second row images

  return (
    <div className="slider-container1">
      <h1 className="slider-title1" id='images'>Actual Images</h1>

      <div className="slider1">
        {firstRowImages.map((img, index) => (
          <div key={index} className="slide1">
            <img src={img} alt={`slide1-${index}`} className="slide1-image" />
          </div>
        ))}
      </div>

      <div className="slider1">
        {secondRowImages.map((img, index) => (
          <div key={index} className="slide1">
            <img src={img} alt={`slide1-${index + 2}`} className="slide1-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliveredProjects;
