import React, { useState } from 'react';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '', // Clear error for this field
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, contact, message } = formData;
    const errors = {};
    if (!name.trim()) {
      errors.name = 'Name is required';
    }
    if (!contact.trim()) {
      errors.contact = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(contact)) {
      errors.contact = 'Mobile number should be exactly 10 digits';
    }
    if (!message.trim()) {
      errors.message = 'Message is required';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      const response = await fetch('https://shiv-8ib2.onrender.com/send-email2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Query sent successfully');
        setFormData({
          name: '',
          contact: '',
          message: '',
        });
      } else {
        alert('Error sending query');
      }
    } catch (error) {
      console.error('Error sending query: ', error);
      alert('Error sending query');
    }
  };

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-right">
          <h3>Send Your Query!</h3>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="form-group">
              <input
                type="tel"
                id="contact"
                name="contact"
                placeholder="Mobile Number"
                value={formData.contact}
                onChange={handleChange}
                required
              />
              {errors.contact && <span className="error">{errors.contact}</span>}
            </div>
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                placeholder="Enter your message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              {errors.message && <span className="error">{errors.message}</span>}
            </div>
            <button className="btn" type="submit">Submit</button>
          </form>
        </div>
        <div className="footer-left">
          <h3>Corporate Office:</h3>
          <p>Address: 196 C, Block C, Sector 63, Noida 201301</p>
          <p>
            <a href="tel:+919582399652">
              <FaPhoneAlt /> Call: +91 9582399652
            </a>
          </p>
          <p>
            <FaEnvelope /> Email: lozanosales8@gmail.com
          </p>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14009.840058679156!2d77.387354!3d28.615972!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cefde1fdb05c1%3A0x1973ad6d3b274e8!2sShiv%20Shakti%20Vihar!5e0!3m2!1sen!2sin!4v1721122862261!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Map Location"
            ></iframe>
          </div>
        </div>
      </div>
      <Link className="privacy-link" to="/privacy">Privacy Policy</Link>
    </div>
  );
};

export default Footer;
