import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './FeaturesSection.css';

const FeaturesSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="features-section">
      <h1 id='service'>Project Highlights</h1>
      <h1 className="title">High-Profit Margin: Government  Approvrd Plots Near Jewar Airport</h1>
      <div className="features-container">
        <div className="feature">
          <div className="feature-icon">
            <img src="https://harithomesresidentialplots.com/img/icon1.png" alt="Infrastructure Icon" />
          </div>
          <h2 className="feature-title">INFRASTRUCTURE</h2>
          <ul className="feature-list">
            <li>Govt. Approved Plots with Govt Bank Loan</li>
            <li>60 Acres Gated Society</li>
            <li>60ft Wide Roads</li>
            <li>Club House, Park, Shopping Complex and More</li>
            <li>Residential and Commercial Complexes</li>
            <li>Schools and World Class Universities</li>
            <li>Hospitals and Entertainment</li>
            <li>Metro connectivity</li>
            <li>Swimming Pool</li>
            
          </ul>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <img src="https://harithomesresidentialplots.com/img/icon2.png" alt="Strategic Location Icon" />
          </div>
          <h2 className="feature-title">STRATEGIC LOCATION</h2>
          <ul className="feature-list">
            <li>Jewar City Property: Near Noida International Airport (Jewar Airport)</li>
            <li>15 minutes distance from Noida Film City</li>
            <li>Proximity to major cities like Delhi, Ghaziabad and Noida</li>
            <li>Yumuna Expressway connects Greater Noida to Agra</li>
            <li>Connectivity with the Delhi-Mumbai Expressway</li>
            <li>F1 Circuit Proximity</li>
            <li>Shopping Complex</li>
            
          </ul>
        </div>
        <div className="feature">
          <div className="feature-icon">
            <img src="https://harithomesresidentialplots.com/img/icon3.png" alt="Industrial Development Icon" />
          </div>
          <h2 className="feature-title">INDUSTRIAL DEVELOPMENT</h2>
          <ul className="feature-list">
            <li>Competitive land prices</li>
            <li>IT Parks</li>
            <li>Establishment of factories</li>
            <li>Commercial Complexes</li>
            <li>Special Economic Zone</li>
            <li>Yumuna Expressway Industrial Development Authority</li>
            <li>Noida International Airport</li>
            <li>Park</li>
          </ul>
        </div>
      </div>
      {isVisible && (
        <a
          href="https://wa.me/9582399652"
          className="whatsapp-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp size={32} />
        </a>
      )}
    </div>
  );
};

export default FeaturesSection;
